/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)


require('select2/dist/css/select2.css');
require('bootstrap/dist/css/bootstrap.min.css');




let $ = require('jquery');
require('../css/app.css');

require('select2');
require('bootstrap');

// require('popper');




$('select').select2();

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
$('.dropdown-submenu > a').on("click", function(e) {
    var submenu = $(this); var text = $(this);
    $('.dropdown-submenu .dropdown-menu').removeClass('show');
    submenu.next('.dropdown-menu').addClass('show');
    e.stopPropagation();
    $this.text = 'Ouvrir'
});

$('.dropdown').on("hidden.bs.dropdown", function() {
    var text = $(this);
    // hide any open menus when parent closes
    $('.dropdown-menu.show').removeClass('show');
    $this.text = 'Fermer'
});



